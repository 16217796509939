import GENDER_COEFFICIENTS from "./unicef-reports/genderCoefficients";

const calculateChildren = (coverage, students) => {
    if (coverage === null && students === null) {
        return 0;
    }

    if (coverage === null) {
        return Math.round(students * GENDER_COEFFICIENTS.male) + +Math.round(students * GENDER_COEFFICIENTS.female);
    }

    if (students === null) {
        return Math.round(coverage * GENDER_COEFFICIENTS.male) + +Math.round(coverage * GENDER_COEFFICIENTS.female);
    }

    return (
        Math.round(Math.min(coverage, students) * GENDER_COEFFICIENTS.male) + +Math.round(Math.min(coverage, students) * GENDER_COEFFICIENTS.female)
    );
};

const formatUnicefNarrativeReport = (data) => {
    const regions = {};

    data.forEach((el) => {
        const {
            training,
            region,
            male,
            female,
            total,
            idp,
            high_school_students,
            secondary_school_students,
            primary_school_students,
            high_school_coverage,
            secondary_school_coverage,
            primary_school_coverage,
        } = el;

        if (regions[region] === undefined) {
            regions[region] = {
                Область: region,
                "Кількість вчителів catch-up (Наздоженемо)": 0,
                "Кількість вчителів distance (Дізнайся)": 0,
                "Кількість вчителів (жінки)": 0,
                "Кількість вчителів (чоловіки)": 0,
                "Кількість вчителів ВПО": 0,
                "Загальна кількість вчителів": 0,
                "Кількість дітей catch-up (Наздоженемо)": 0,
                "Кількість дітей distance (Дізнайся)": 0,
                "Загальна кількість дітей": 0,
            };
        }

        const totalStudents =
            +calculateChildren(high_school_coverage, high_school_students) +
            +calculateChildren(secondary_school_coverage, secondary_school_students) +
            +calculateChildren(primary_school_coverage, primary_school_students);

        if (training.toLowerCase().includes("наздоженемо")) {
            regions[region]["Кількість вчителів catch-up (Наздоженемо)"] += +total;
            regions[region]["Кількість дітей catch-up (Наздоженемо)"] += +totalStudents;
        }

        if (training.toLowerCase().includes("дізнайся")) {
            regions[region]["Кількість вчителів distance (Дізнайся)"] += +total;
            regions[region]["Кількість дітей distance (Дізнайся)"] += +totalStudents;
        }

        regions[region]["Кількість вчителів (чоловіки)"] += +male;
        regions[region]["Кількість вчителів (жінки)"] += +female;
        regions[region]["Загальна кількість вчителів"] += +total;
        regions[region]["Кількість вчителів ВПО"] += +idp;
        regions[region]["Загальна кількість дітей"] += +totalStudents;
    });

    return Object.values(regions);
};

export default formatUnicefNarrativeReport;
