const getHpdIndicator = (training, role) => {
    training = training.toLowerCase();

    if (role === "teacher") {
        switch (true) {
            case training.includes("minecraft"):
                return "[UKR/PCA202279/SPD2023261/1.2.1] of teachers/facilitators in Digital Learning Centers who are trained on using learning through play tools (Minecraft Education)";
            case training.includes("дізнайся"):
                return "[UKR/PCA202279/SPD2023261/1.2.3] of teachers in primary and secondary schools trained on distance and blended learning techniques";
            case training.includes("наздоженемо"):
                return "[UKR/PCA202279/SPD2023261/1.1.2] of teachers and educational personnel trained to address learning loss through remedial and catch-up learning";
            default:
                return "";
        }
    }

    if (role === "student") {
        switch (true) {
            case training.includes("дізнайся"):
                return "[UKR/PCA202279/SPD2023261/1.2.2] of children benefiting from teachers trained on distance and blended learning techniques";
            case training.includes("наздоженемо"):
                return "[UKR/PCA202279/SPD2023261/1.1.1] of children benefiting from teachers trained to address learning loss through remedial and catch-up learning";
            default:
                return "";
        }
    }

    return "";
};

export default getHpdIndicator;
