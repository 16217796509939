import CustomDateInput from "../../UI/CustomDateInput/CustomDateInput";

const ReportExportsModal = ({ handler, closeHandler, startValue, startValueHandler, endValue, endValueHandler }) => {
    return (
        <div className='report-modal'>
            <div className='report-modal-window'>
                <button className='report-modal-window-close-button' onClick={closeHandler}>
                    x
                </button>
                <h2>Оберіть період звітності:</h2>
                <CustomDateInput label='Від:' value={startValue} handler={startValueHandler} />
                <CustomDateInput label='До:' value={endValue} handler={endValueHandler} />
                {startValue > endValue && <div className='report-modal-window-error'>Невірний діапазон дат!</div>}
                <button className='report-modal-window-submit-button' onClick={() => handler(false)} disabled={startValue > endValue ? true : ""}>
                    Завантажити (унікальні за весь час)
                </button>
                <button className='report-modal-window-submit-button' onClick={() => handler(true)} disabled={startValue > endValue ? true : ""}>
                    Завантажити (унікальні за вказаний період)
                </button>
            </div>
        </div>
    );
};

export default ReportExportsModal;
