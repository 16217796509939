const getHumanitarianAssistanceName = (trainingTitle) => {
    const trainingTitleLowerCase = trainingTitle.toLowerCase();

    switch (true) {
        case trainingTitleLowerCase.includes("minecraft"):
            return "Training for DLC (Minecraft Education)";
        case trainingTitleLowerCase.includes("дізнайся"):
            return "Training for teachers on distance and blended learning techniques";
        case trainingTitleLowerCase.includes("наздоженемо"):
            return "Training for teachers: CATCH-UP";
        default:
            return trainingTitle;
    }
};

export default getHumanitarianAssistanceName;
