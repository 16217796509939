const formatNewUnicefNarrativeReport = (data) => {
    const regions = {};

    data.forEach((el) => {
        const { training, region, male, female, total, idp, primary_school, secondary_school, high_school } = el;

        if (regions[region] === undefined) {
            regions[region] = {
                Область: region,
                "Кількість вчителів catch-up (Наздоженемо)": 0,
                "Кількість вчителів distance (Дізнайся)": 0,
                "Кількість вчителів (жінки)": 0,
                "Кількість вчителів (чоловіки)": 0,
                "Кількість вчителів ВПО": 0,
                "Загальна кількість вчителів": 0,
                "Кількість дітей catch-up (Наздоженемо)": 0,
                "Кількість дітей distance (Дізнайся)": 0,
                "Загальна кількість дітей": 0,
            };
        }

        const totalStudents = +primary_school + +secondary_school + +high_school;

        if (training.toLowerCase().includes("наздоженемо")) {
            regions[region]["Кількість вчителів catch-up (Наздоженемо)"] += +total;
            regions[region]["Кількість дітей catch-up (Наздоженемо)"] += +totalStudents;
        }

        if (training.toLowerCase().includes("дізнайся")) {
            regions[region]["Кількість вчителів distance (Дізнайся)"] += +total;
            regions[region]["Кількість дітей distance (Дізнайся)"] += +totalStudents;
        }

        regions[region]["Кількість вчителів (чоловіки)"] += +male;
        regions[region]["Кількість вчителів (жінки)"] += +female;
        regions[region]["Загальна кількість вчителів"] += +total;
        regions[region]["Кількість вчителів ВПО"] += +idp;
        regions[region]["Загальна кількість дітей"] += +totalStudents;
    });

    return Object.values(regions);
};

export default formatNewUnicefNarrativeReport;
