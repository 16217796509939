import getHumanitarianAssistanceName from "./unicef-reports/getHumanitarianAssistanceName";
import getChildrenQuantity from "./unicef-reports/getChildrenQuantity";
import getHpdIndicator from "./unicef-reports/getHpdIndicator";

const formatNewUnicefReport = (data, startDate, endDate) => {
    const ReportingDate = new Date().toDateString();
    const ReportingSitRep =
        startDate.getMonth() === endDate.getMonth() ? startDate.getMonth() + 1 : `${startDate.getMonth() + 1}-${endDate.getMonth() + 1}`;

    const result = [];

    data.forEach((record) => {
        const { training, school_id, name, female, male, total, idp, disablement } = record;

        const trainingName = getHumanitarianAssistanceName(training);
        const endBeneficiaryName = school_id ? "Secondary Education Facility (school)" : "Individuals";
        const school = school_id !== null ? school_id : name;
        const adultsFemale = female === "0" ? "" : female;
        const adultsMale = female === "0" ? "" : male;
        const idpTotal = idp === "0" ? "" : idp;
        const disablementTotal = disablement === "0" ? "" : disablement;
        const pdIndicator = getHpdIndicator(training, "teacher");

        result.push({
            Name: trainingName,
            Quantity: "1",
            Unit: "trainings",
            "Waybill Number": "",
            "Delivered Assistance To": "",
            "Implementing Partner Name": "Osvitoria NGO [2500242682]",
            "End Beneficiary Type": endBeneficiaryName,
            "End Beneficiary Name": school,
            "Delivered To End Beneficiaries": "yes",
            Grant: "",
            Oblast: "",
            Rayon: "",
            Hromada: "",
            Settlement: "",
            "Reporting SitRep": ReportingSitRep,
            "Reporting Date": ReportingDate,
            "Children <5 (Female)": "",
            "Children 5-9 (Female)": "",
            "Children 10-14 (Female)": "",
            "Children 15-17 (Female)": "",
            "Children 0-17 (Female)": "",
            "Children <5 (Male)": "",
            "Children 5-9 (Male)": "",
            "Children 10-14 (Male)": "",
            "Children 15-17 (Male)": "",
            "Children 0-17 (Male)": "",
            "Children 0-17 Total": "",
            "Children IDPs Total": "",
            "Children With Disabilities Total": "",
            "Adults Female": adultsFemale,
            "Adults Male": adultsMale,
            "Adults Total": total,
            "Adults IDPs Total": idpTotal,
            "Adults With Disabilities Total": disablementTotal,
            "Absolute Number Of Beneficiaries": total,
            "Beneficiaries Unique": "yes",
            "Operational Field": "Education",
            "PD Indicator": pdIndicator,
            "AWP Indicator": "[HAC] # teachers and other education personnel trained to provide quality learning [AWP240504]",
            "HPM Handbook Activity": "Online and face-to-face training of school and KG teacher [AWP24050401]",
            Contact: "Dmytro Sharaievsky",
        });
    });

    data.forEach((record) => {
        const { training, school_id, name, primary_school, secondary_school, high_school } = record;

        if (training.includes("minecraft")) return;

        const trainingName = getHumanitarianAssistanceName(training);
        const endBeneficiaryName = school_id ? "Secondary Education Facility (school)" : "Individuals";
        const school = school_id !== null ? school_id : name;
        const Children5_9Fem = getChildrenQuantity(primary_school, "female");
        const Children10_14Fem = getChildrenQuantity(secondary_school, "female");
        const Children15_17Fem = getChildrenQuantity(high_school, "female");
        const Children0_17Fem = Children5_9Fem + Children10_14Fem + Children15_17Fem;
        const Children5_9Male = getChildrenQuantity(primary_school, "male");
        const Children10_14Male = getChildrenQuantity(secondary_school, "male");
        const Children15_17Male = getChildrenQuantity(high_school, "male");
        const Children0_17Male = Children5_9Male + Children10_14Male + Children15_17Male;
        const ChildrenTotal = Children0_17Fem + Children0_17Male;
        const pdIndicator = getHpdIndicator(training, "student");

        result.push({
            Name: trainingName,
            Quantity: "1",
            Unit: "trainings",
            "Waybill Number": "",
            "Delivered Assistance To": "",
            "Implementing Partner Name": "Osvitoria NGO [2500242682]",
            "End Beneficiary Type": endBeneficiaryName,
            "End Beneficiary Name": school,
            "Delivered To End Beneficiaries": "yes",
            Grant: "",
            Oblast: "",
            Rayon: "",
            Hromada: "",
            Settlement: "",
            "Reporting SitRep": ReportingSitRep,
            "Reporting Date": ReportingDate,
            "Children <5 (Female)": "",
            "Children 5-9 (Female)": Children5_9Fem,
            "Children 10-14 (Female)": Children10_14Fem,
            "Children 15-17 (Female)": Children15_17Fem,
            "Children 0-17 (Female)": Children0_17Fem,
            "Children <5 (Male)": "",
            "Children 5-9 (Male)": Children5_9Male,
            "Children 10-14 (Male)": Children10_14Male,
            "Children 15-17 (Male)": Children15_17Male,
            "Children 0-17 (Male)": Children0_17Male,
            "Children 0-17 Total": ChildrenTotal,
            "Children IDPs Total": "",
            "Children With Disabilities Total": "",
            "Adults Female": "",
            "Adults Male": "",
            "Adults Total": "",
            "Adults IDPs Total": "",
            "Adults With Disabilities Total": "",
            "Absolute Number Of Beneficiaries": ChildrenTotal,
            "Beneficiaries Unique": "yes",
            "Operational Field": "Education",
            "PD Indicator": pdIndicator,
            "AWP Indicator": "Other [AWP240599]",
            "HPM Handbook Activity": "",
            Contact: "Dmytro Sharaievsky",
        });
    });

    return result;
};

export default formatNewUnicefReport;
