import GENDER_COEFFICIENTS from "./genderCoefficients";

const getChildrenQuantity = (quantity, gender = "male") => {
    if (quantity === null || quantity === undefined) {
        return 0;
    }

    return Math.round(quantity * GENDER_COEFFICIENTS[gender]);
};

export default getChildrenQuantity;
