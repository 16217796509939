const genderCoefficients = {
    male: 0.49,
    female: 0.51,
};

const createHumanitarianAssistanceName = (trainingTitle) => {
    const trainingTitleLowerCase = trainingTitle.toLowerCase();
    switch (true) {
        case trainingTitleLowerCase.includes("minecraft"):
            return "Training for DLC (Minecraft Education)";
        case trainingTitleLowerCase.includes("дізнайся"):
            return "Training for teachers: CATCH-UP";
        case trainingTitleLowerCase.includes("наздоженемо"):
            return "Training for teachers on distance and blended learning techniques";
        default:
            return trainingTitle;
    }
};

const hpdIndicatorUtil = (hpd, role) => {
    hpd = hpd.toLowerCase();

    if (role === "teacher") {
        switch (true) {
            case hpd.includes("minecraft"):
                return "# of teachers/facilitators in Digital Learning Centers who are trained on using learning through play tools";
            case hpd.includes("дізнайся"):
                return "[UKR/PCA202279/SPD2023261/1.2.3] of teachers in primary and secondary schools trained on distance and blended learning techniques";
            case hpd.includes("наздоженемо"):
                return "[UKR/PCA202279/SPD2023261/1.1.2] of teachers and educational personnel trained to address learning loss through remedial and catch-up learning";
            default:
                return "";
        }
    }

    if (role === "student") {
        switch (true) {
            case hpd.includes("дізнайся"):
                return "# of children benefiting from teachers trained on distance and blended learning techniques";
            case hpd.includes("наздоженемо"):
                return "# of children benefiting from teachers trained to address learning loss through remedial and catch-up learning";
            default:
                return "";
        }
    }

    return "";
};

const calculateChildrens = (coverage, students, gender = "male") => {
    if (coverage === null && students === null) {
        return 0;
    }

    if (coverage === null) {
        return Math.round(students * genderCoefficients[gender]);
    }

    if (students === null) {
        return Math.round(coverage * genderCoefficients[gender]);
    }

    return Math.round(Math.min(coverage, students) * genderCoefficients[gender]);
};

const formatUnicefReport = (data, startDate, endDate) => {
    let ReportingDate = new Date().toDateString();
    let ReportingSitRep =
        startDate.getMonth() === endDate.getMonth() ? startDate.getMonth() + 1 : `${startDate.getMonth() + 1}-${endDate.getMonth() + 1}`;

    const result = [];

    data.forEach((el) => {
        const school = el.school_id !== null ? el.school_id : el.name;
        result.push({
            Name: createHumanitarianAssistanceName(el.training),
            Quantity: 1,
            Unit: "trainings",
            "Waybill Number": "",
            "Delivered Assistence To": "",
            "Implementing Partner Name": "Osvitoria NGO [2500242682]",
            "End Beneficiary Type": el.school_id ? "Secondary Education Facility (school)" : "Individuals",
            "End Beneficiary Name": school,
            "Delivered To End Beneficiaries": "yes",
            Grant: "",
            Oblast: "",
            Rayon: "",
            Hromada: "",
            Settlement: "",
            "Reporting SitRep": ReportingSitRep,
            "Reporting Date": ReportingDate,
            "Children <5 (Female)": "",
            "Children 5-9 (Female)": "",
            "Children 10-14 (Female)": "",
            "Children 15-17 (Female)": "",
            "Children 0-17 (Female)": "",
            "Children <5 (Male)": "",
            "Children 5-9 (Male)": "",
            "Children 10-14 (Male)": "",
            "Children 15-17 (Male)": "",
            "Children 0-17 (Male)": "",
            "Children 0-17 Total": "",
            "Children IDPs Total": "",
            "Children With Disabilities Total": "",
            "Adults Female": el.female === "0" ? "" : el.female,
            "Adults Male": el.male === "0" ? "" : el.male,
            "Adults Total": el.total,
            "Adults IDPs Total": el.idp === "0" ? "" : el.idp,
            "Adults With Disabilities Total": el.disablement === "0" ? "" : el.disablement,
            "Absolute Number Of Beneficiaries": el.total,
            "Beneficiaries Unique": "yes",
            "Operational Field": "Education",
            "PD Indicator": hpdIndicatorUtil(el.training, "teacher"),
            "AWP Indicator": "[HAC] # teachers and other education personnel trained to provide quality learning [AWP240504]",
            "HPM Handbook Activity": "Online and face-to-face training of school and KG teache [AWP24050401]",
            Contact: "Dmytro Sharaievsky",
        });
    });

    data.forEach((el) => {
        const school = el.school_id !== null ? el.school_id : el.name;

        const Children5_9Fem = calculateChildrens(el.primary_school_coverage, el.primary_school_students, "female");
        const Children10_14Fem = calculateChildrens(el.secondary_school_coverage, el.secondary_school_students, "female");
        const Children15_17Fem = calculateChildrens(el.high_school_coverage, el.high_school_students, "female");
        const Children0_17Fem = Children5_9Fem + Children10_14Fem + Children15_17Fem;

        const Children5_9Male = calculateChildrens(el.primary_school_coverage, el.primary_school_students, "male");
        const Children10_14Male = calculateChildrens(el.secondary_school_coverage, el.secondary_school_students, "male");
        const Children15_17Male = calculateChildrens(el.high_school_coverage, el.high_school_students, "male");
        const Children0_17Male = Children5_9Male + Children10_14Male + Children15_17Male;

        const ChildrenTotal = Children0_17Fem + Children0_17Male;

        result.push({
            Name: createHumanitarianAssistanceName(el.training),
            Quantity: 1,
            Unit: "trainings",
            "Waybill Number": "",
            "Delivered Assistence To": "",
            "Implementing Partner Name": "Osvitoria NGO [2500242682]",
            "End Beneficiary Type": el.school_id ? "Secondary Education Facility (school)" : "Individuals",
            "End Beneficiary Name": school,
            "Delivered To End Beneficiaries": "yes",
            Grant: "",
            Oblast: "",
            Rayon: "",
            Hromada: "",
            Settlement: "",
            "Reporting SitRep": ReportingSitRep,
            "Reporting Date": ReportingDate,
            "Children <5 (Female)": "",
            "Children 5-9 (Female)": Children5_9Fem,
            "Children 10-14 (Female)": Children10_14Fem,
            "Children 15-17 (Female)": Children15_17Fem,
            "Children 0-17 (Female)": Children0_17Fem,
            "Children <5 (Male)": "",
            "Children 5-9 (Male)": Children5_9Male,
            "Children 10-14 (Male)": Children10_14Male,
            "Children 15-17 (Male)": Children15_17Male,
            "Children 0-17 (Male)": Children0_17Male,
            "Children 0-17 Total": ChildrenTotal,
            "Children IDPs Total": "",
            "Children With Disabilities Total": "",
            "Adults Female": "",
            "Adults Male": "",
            "Adults Total": "",
            "Adults IDPs Total": "",
            "Adults With Disabilities Total": "",
            "Absolute Number Of Beneficiaries": ChildrenTotal,
            "Beneficiaries Unique": "yes",
            "Operational Field": "Education",
            "PD Indicator": hpdIndicatorUtil(el.training, "student"),
            "AWP Indicator": "Other [AWP240599]",
            "HPM Handbook Activity": "",
            Contact: "Dmytro Sharaievsky",
        });
    });

    return result;
};

module.exports = formatUnicefReport;
